@import "common";

@viewport {
	// TODO: Is this right?
	width: extend-to-zoom;
	zoom: 1.0; }

body {
	margin: 0;
	display: flex;
	flex-direction: column;

	font-family: sans-serif; }

.page_content {
	box-sizing: border-box;
	margin: 0 auto;
	width: 100%;
	max-width: 250mm;
	background-color: $colour4; }

//Should make images behave
img {
	width: 100%;
	height: auto;
	object-fit: contain;
	display: block; }

nav.main {
	z-index: 1;
	.bolt-menu {
		list-style: none;
		background-color: $colour1;
		margin: 0;
		padding: 0;
		padding-left: 10mm;
		padding-bottom: 2mm;
		padding-top: 2mm;
		box-shadow: 0 0 4mm #000000;

		li {
			display: inline;
			padding-right: 5mm;

			a {
				color: #ffffff;
				text-decoration: none; } }

		li:last-child {
			float: right; } } }

// Common, but not used everywhere

.post {
	display: flex;
	flex-direction: column;
	margin: 3mm;
	border-style: solid;
	border-width: 3mm;
	border-radius: 0.5mm;
	border-color: $colour3;
	background-color: $colour3;
	box-shadow: 0mm 0mm 1mm 1mm #999999;

	.title {
		font-size: 20pt;
		text-decoration: none;
		color: $colour1; }

	.date {
		font-size: 10pt;
		color: $colour5;
		margin-bottom: 3mm; }

	.content {
		font-size: 12pt; } }

